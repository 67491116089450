<template>
  <div class="console">
    <a-spin :spinning="loading">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-page-header title="数据概况"></a-page-header>
        </a-col>
        <a-col>
          <a-select v-model="date" style="width: 180px" @change="onChange">
            <a-select-option value="1">今日</a-select-option>
            <a-select-option value="2">昨日</a-select-option>
            <a-select-option value="3">最近7天</a-select-option>
            <a-select-option value="4">最近30天</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <div class="pannel">
        <a-row :gutter="16" type="flex">
          <a-col :flex="1">
            <div class="item">
              <a-statistic title="用户总数" :value="pannelData.userTotal" />
            </div>
          </a-col>
          <a-col :flex="1">
            <div class="item">
              <a-statistic title="新增用户" :value="pannelData.userCount" />
            </div>
          </a-col>
          <a-col :flex="1">
            <div class="item">
              <a-statistic
                title="新发布文章"
                :value="pannelData.articleCount"
              />
            </div>
          </a-col>
          <a-col :flex="1">
            <div class="item">
              <a-statistic
                title="浏览量PV"
                :value="pannelData.pv === '--' ? 0 : pannelData.pv"
              />
            </div>
          </a-col>
          <a-col :flex="1">
            <div class="item">
              <a-statistic
                title="访客数UV"
                :value="pannelData.uv === '--' ? 0 : pannelData.uv"
              />
            </div>
          </a-col>
          <a-col :flex="1">
            <div class="item">
              <a-statistic
                title="IP"
                :value="pannelData.ip === '--' ? 0 : pannelData.ip"
              />
            </div>
          </a-col>
        </a-row>
      </div>
      <a-row type="flex" justify="space-between" style="margin-top: 32px">
        <a-col>
          <a-page-header title="趋势分析"></a-page-header>
        </a-col>
        <a-col>
          <a-select
            v-model="trendDateType"
            style="width: 180px"
            @change="onTrendDateTypeChange"
          >
            <a-select-option value="1">最近7天</a-select-option>
            <a-select-option value="2">最近30天</a-select-option>
            <a-select-option value="3">最近60天</a-select-option>
            <a-select-option value="4">最近90天</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <div class="trend">
        <div id="container"></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Line } from '@antv/g2plot';

export default {
  name: 'Console',
  data() {
    return {
      date: '1',
      pannelData: {},
      loading: false,
      trendDateType: '1',
      trendData: {},
      lineChart: null,
    };
  },
  mounted() {
    // 获取面板数据
    this.getPanelData();
    // 获取趋势数据
    this.getTrendData();
  },
  methods: {
    // 日期选择
    onChange() {
      this.getPanelData();
    },
    // 获取面板数据
    async getPanelData() {
      let date, start_date, end_date;
      // 今日
      if (this.date === '1') {
        date = this.$dayjs().format('YYYY-MM-DD');
        start_date = end_date = undefined;
      }
      // 昨日
      if (this.date === '2') {
        date = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        start_date = end_date = undefined;
      }
      // 最近7天
      if (this.date === '3') {
        date = undefined;
        start_date = this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      // 最近30天
      if (this.date === '4') {
        date = undefined;
        start_date = this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      const data = { date, start_date, end_date };
      this.loading = true;
      const { err, res } = await this.$api.console.pannelData(data);
      if (!err) {
        this.pannelData = res.result;
      }
      this.loading = false;
    },
    // 获取趋势数据
    async getTrendData() {
      let start_date, end_date;
      // 最近7天
      if (this.trendDateType === '1') {
        start_date = this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      // 最近30天
      if (this.trendDateType === '2') {
        start_date = this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      // 最近60天
      if (this.trendDateType === '3') {
        start_date = this.$dayjs().subtract(59, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      // 最近90天
      if (this.trendDateType === '4') {
        start_date = this.$dayjs().subtract(89, 'day').format('YYYY-MM-DD');
        end_date = this.$dayjs().format('YYYY-MM-DD');
      }
      const data = { start_date, end_date };
      this.loading = true;
      const { err, res } = await this.$api.console.trendData(data);
      if (!err) {
        this.trendData = res.result;
        console.log(res.result);
        let data = [];
        data = this.trendData.date.map((item, index) => {
          return {
            date: item,
            value: this.trendData.pv[index],
            category: 'PV',
          };
        });
        data = data.concat(
          this.trendData.date.map((item, index) => {
            return {
              date: item,
              value: this.trendData.uv[index],
              category: 'UV',
            };
          })
        );
        data = data.concat(
          this.trendData.date.map((item, index) => {
            return {
              date: item,
              value: this.trendData.ip[index],
              category: 'IP',
            };
          })
        );
        if (this.lineChart) this.lineChart.destroy();
        this.lineChart = new Line('container', {
          data,
          xField: 'date',
          yField: 'value',
          smooth: true,
          seriesField: 'category',
          // point: {
          //   size: 4,
          //   shape: 'circle',
          // },
          color: ['#5B8FF9', '#5AD8A6', '#503075'],
        });

        this.lineChart.render();
      }
      this.loading = false;
    },
    // 趋势日期选择
    onTrendDateTypeChange() {
      this.getTrendData();
    },
  },
};
</script>

<style lang="less" scoped>
.console {
  .ant-page-header {
    padding-left: 0;
    padding-top: 0;
  }
  .pannel {
    ::v-deep .item {
      border: #f0f2f5 solid 2px;
      padding: 16px;
      border-radius: 8px;
      .ant-statistic-title {
        font-size: 16px;
      }
      .ant-statistic-content {
        font-size: 28px;
      }
    }
  }
  .trend {
    border: #f0f2f5 solid 2px;
    padding: 32px;
    border-radius: 8px;
  }
}
</style>
